import { Button, Form, Input, Flex, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { apiReg } from '../../../api/auth'
import { HeaderLogo } from '../../../components/HeaderLogo'
const Reg = () => {
  const [form] = Form.useForm()
  const nav = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  const handleReg = async ({
    email,
    password,
    retryPassword
  }: {
    email: string
    password: string
    retryPassword: string
  }) => {
    if (password !== retryPassword) {
      messageApi.error('Введенные пароли не совпадают')
    } else {
      const result = await apiReg({ email, password })
      if (result.isError) {
        if (result.data?.detail === 'User with this email almost exist') {
          messageApi.warning('Пользователь с такой электронной почтой уже зарегестрирован')
        } else {
          messageApi.error('Произошла ошибка, попробуйте повторить регистрацию')
        }
      } else {
        messageApi.success(
          'Регистрация успешно прошла. На вашу электронную почту высланое письмо для подтверждения регистрации'
        )
      }
    }
  }

  return (
    <>
      {contextHolder}
      <HeaderLogo size='big' />
      <Form {...layout} form={form} onFinish={handleReg} size='large'>
        <Form.Item
          label='Электронная почта'
          name='email'
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Пароль'
          name='password'
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label='Повторно пароль'
          name='retryPassword'
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>
        <Flex align='center' vertical gap={4}>
          <Button htmlType='submit' type='primary' style={{ width: '100%' }}>
            Зарегистрироваться
          </Button>
          <Button htmlType='button' type='link' onClick={() => nav('/auth/login')}>
            Есть логин и пароль
          </Button>
        </Flex>
      </Form>
    </>
  )
}

export default Reg
