const API_URL: string | undefined = "https://api.voicecover.ru/"

const updateToken = async (url: string = '/auth/refresh_token') => {
    const token = localStorage.getItem('refreshToken');
    if (token) {
        const response = await fetch(API_URL + url, { method: 'POST', headers: { Authorization: token, } })
        if (response.status === 403) {
            throw new Error('Forbidden')
        } else {
            const data = await response.json()
            localStorage.setItem('accessToken', data?.access_token)
            localStorage.setItem('refreshToken', data?.refresh_token)
        }
    }

}

const handlerFetch = async <T>(
    endpoint: RequestInfo | URL,
    init?: RequestInit | undefined | any,
    protectedMode: boolean = false,
    tryLogin: number = 2,
    returnBlob: boolean = false,
    signal?: AbortSignal | undefined): Promise<Api.Response<T>> => {
    try {
        let countTryLogin = tryLogin
        if (!init) {
            init = {
                headers: {},
                method: 'GET',
            };
        }
        if (!init.headers) {
            init.headers = {};
        }
        if (protectedMode) {
            const token = localStorage.getItem('accessToken');
            if (token) {
                init.headers['Authorization'] = `Bearer ${token}`
            } else {
                throw new Error('Need token')
            }
        }

        if (API_URL) {
            // console.log(init);
            // console.log(API_URL);
            // console.log(endpoint);

            const response = await fetch(API_URL + endpoint, { ...init, signal })
            // console.log(response);

            if (response.status === 403) {
                if (countTryLogin >= 0) {
                    await updateToken()
                    countTryLogin = countTryLogin - 1
                    return handlerFetch(endpoint, init, protectedMode, countTryLogin)
                } else {
                    throw new Error((await response.json()).detail)
                }

            }
            if (response.status !== 200) {
                const data = await response.json()
                return {
                    isError: true,
                    data
                }
            }
            else {
                if (returnBlob) {
                    const blob = await response.blob();
                    return {
                        isError: false,
                        // @ts-ignore
                        blob
                    }
                } else {
                    const data = await response.json();
                    return {
                        isError: false,
                        data
                    }
                }
            }

        } else {
            throw new Error('API URL not found')
        }

    } catch (error) {
        const err = error as Error;
        return {
            isError: true,
            data: {
                detail: [
                    {
                        loc: ['unknown', 0],
                        msg: err.message,
                        type: 'unknown',
                    },
                ],
            },
        };
    }
}

export default handlerFetch
