import { CoreSection } from './Core'
import { Row, Col, Typography, theme, Button } from 'antd'
import imgS1 from './../../../assets/s1.png'
import imgS2 from './../../../assets/s2.png'
import imgS3 from './../../../assets/s3.png'
import GetContact from '../../../components/modals/GetContact/GetContact'
import React from 'react'
import { usePageLeave } from 'react-use'
import { useNavigate } from 'react-router-dom'
const { useToken } = theme

const AboutSection = () => {
  const { token } = useToken()
  const [openModal, setOpenModal] = React.useState(false)
  const nav = useNavigate()
  usePageLeave(() => {
    const isShowPromoModal = localStorage.getItem('isShowPromoModal')
    !isShowPromoModal && setOpenModal(true)
    localStorage.setItem('isShowPromoModal', 'true')
  })
  return (
    <CoreSection>
      <GetContact isOpen={openModal} onCancel={setOpenModal} />
      <Row
        align='middle'
        style={{ height: '100%' }}
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Typography.Title level={5}>VOICECOVER | АВТОПЕРЕВОД</Typography.Title>
          <Typography.Title level={1}>
            Языковой барьер больше не проблема с{' '}
            <span style={{ color: token.colorPrimary }}>автоматическим переводом ваших видео</span>
          </Typography.Title>
          <Typography.Paragraph>
            Расширяйте вашу аудиторию, повышайте вовлеченность и увеличивайте конверсию с помощью услуги автоматического
            перевода видео с Voicecover
          </Typography.Paragraph>
          <Button type='primary' onClick={() => nav('/auth/login')}>
            Попробовать
          </Button>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <iframe
            src='https://www.youtube.com/embed/hhhKo5Fg_uU?si=RIc-dfPQj4h0RIyI'
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
            style={{ height: '400px', width: '100%', borderRadius: '16px' }}
          ></iframe>
        </Col>
      </Row>
      <Row
        align='middle'
        style={{ height: '100%' }}
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <img src={imgS1} alt='' width='600px' />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Typography.Title level={5}>
            <span style={{ color: token.colorPrimary }}>Алгоритмы ИИ на вашей службе</span>
          </Typography.Title>
          <Typography.Title level={1}>Попрощайтесь с трудоемким ручным переводом.</Typography.Title>
          <Typography.Paragraph>
            В Voicecover, мы раскрыли потенциал современного автоматического видеоперевода, основанного на передовых
            алгоритмах искусственного интеллекта. В отличие от других решений, наша платформа обеспечивает быстрый
            перевод с хорошей точностью, гарантируя, что ваше переведенное видео будет понятно вашей зарубежной целевой
            аудитории.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row
        align='middle'
        style={{ height: '100%' }}
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Typography.Title level={5}>
            <span style={{ color: token.colorPrimary }}>Учли ваши предложения</span>
          </Typography.Title>
          <Typography.Title level={1}>Дополнительная работа с субтитрами.</Typography.Title>
          <Typography.Paragraph>
            Для тех, кому важны выверенные слова, мы реализовали редактор переведенных субтитров, чтобы вы могли
            отредактировать определенные фрагменты перед финальной озвучкой видео. А еще вы можете скачать субтитры в
            формате srt и к примеру загрузить их на Youtube.
          </Typography.Paragraph>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <img src={imgS2} alt='' width='600px' />
        </Col>
      </Row>
      <Row align='middle' style={{ height: '100%' }} gutter={64}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <img src={imgS3} alt='' width='600px' />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Typography.Title level={5}>
            <span style={{ color: token.colorPrimary }}>Внимание к деталям</span>
          </Typography.Title>
          <Typography.Title level={1}>Удобная настройка автоперевода.</Typography.Title>
          <Typography.Paragraph>
            Опционально доступны настройки языка перевода, пол, голос диктора, синхронизация губ, возможность сохранения
            или погашения оригинальной речи в звуоковой дорожке с сохранением музыкального и звукового фона.
          </Typography.Paragraph>
        </Col>
      </Row>
    </CoreSection>
  )
}

export default AboutSection
