import { CoreSection } from "./Core"
import { Row, Typography, Card, Col, theme, Flex} from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
const { useToken } = theme
const PossibilitiesSection = () => {
    const { token } = useToken();
    return <CoreSection>
        <Typography.Title level={1}>Возможности</Typography.Title>
        <Row align='stretch' gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]} >
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6, }} >
                <Card title='Редактирование субтиртров'  extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция редактирования переведенных субтитров перед их синтезом позволяет пользователю отредактировать нужные фрагменты.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Наложение субтитров' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция наложения субтитров позволяет "прожечь" на видео синхронизированные переведенные субтитры или скачать их в формате srt и загрузить на YouTube.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Пол и голос озвучки' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция синхронизации губ или lipsync, технология, позволяющая синхронизировать движение губ спикера в соотвествии с переведенной звуковой дорожкой.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Синхронизация губ' extra={<CloseCircleOutlined style={{ color: token.colorError }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция редактирования переведенных субтитров перед их синтезом позволяет пользователю отредактировать нужные фрагменты.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Подавление речи' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция подавление речи позволяет "вырезать" или "приглушить" оригинальную речи из звуковой дорожки, сохранив при этом фоновое звуковое сопровождение.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Клонирование голоса' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Функция клонирования голоса спикера оригинальной дорожки позволяет имитировать синтезированную переведенную речь голосом автора видео.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='Приватность ваших данных' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Все функции сервиса начиная транскрибацией и заканчивая синтезом переведенной речи, выполняются на собственных серверах. Voicecover не использует сторонние сервисы и услуги для автоматического перевода видео.
                    </Typography.Paragraph>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card title='14 языков перевода' extra={<CheckCircleOutlined style={{ color: token.colorSuccess }} />} style={{ height: '100%'}}>
                    <Typography.Paragraph>
                        Сервис позволяет выбрать направление перевода на любой из 14 доступных языков: английский, французский, немецкий, итальянский, испанский и другие.
                    </Typography.Paragraph>
                </Card>
            </Col>
        </Row>
    </CoreSection>
}
export default PossibilitiesSection