import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from './router'
import { store } from './store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import theme from './theme.json';
import { UserProvider } from './components/UserProvider';
import { SpinLoader } from './components/SpinLoader';
import locale from 'antd/locale/ru_RU';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider theme={theme} locale={locale}>
    <Provider store={store}>
      <UserProvider>
        <RouterProvider router={router} fallbackElement={<SpinLoader />} />
      </UserProvider>
    </Provider>
  </ConfigProvider>
);
