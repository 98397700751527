import { useEffect, useState } from 'react'
import Editor from './Editor'
import { Caption, parseSrt, parseVtt } from '../../../utils/editor/caption'
import { apiGetBlob, apiGetOne } from '../../../api/crud'
import { Card, Flex, Spin } from 'antd'
import preview from '../../../assets/stab.png'

const EditorWrapper = ({ id, setCurrent }: { id: string; setCurrent: any }) => {
  //   const file = useFileStore((state) => state.fileVideo);
  //   const setFile = useFileStore((state) => state.setFile);
  const [videoUrl, setVideoUrl] = useState('')
  const [srt, setSrt] = useState<Caption[] | null>(null)
  const [loading, setLoading] = useState(false)
  const file = localStorage.getItem('fileURL') || ''
  const [img, setImg] = useState(preview)
  const abortController = new AbortController()
  const getSubtitles = async () => {
    try {
      const req = await apiGetOne(`translate/${id}/rusub/`)
      if (!req.isError) {
        setSrt(parseVtt(req.data.vtt))
      } else {
        console.error(req.data.detail)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const getVideo = async () => {
    try {
      setLoading(true)
      const req = await apiGetBlob(`translate/${id}/download/video_origin/`)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      setLoading(false)
      //@ts-ignore
      setVideoUrl(URL.createObjectURL(req.blob))
    } catch (err) {
      console.error(err)
    }
  }
  const getPreview = async () => {
    try {
      const req = await apiGetBlob(`translate/${id}/download/preview/`, abortController.signal)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      //@ts-ignore
      setImg(URL.createObjectURL(req.blob))
    } catch (err) {
      console.error(err)
    }
  }

  //Получение сабов
  useEffect(() => {
    getSubtitles()
    getPreview()
    // setVideoUrl(getVideoUrl(id))
    getVideo()
  }, [id])

  return (
    <div className='mt-5'>
      <Card title={`Подтвердите корректность или отредактируйте субтитры`} style={{ width: '100%' }}>
        <Spin spinning={loading} delay={500}>
          <Editor videoUrl={videoUrl} subtitles={srt ? srt : []} id={id} setCurrent={setCurrent} />
        </Spin>
      </Card>
    </div>
  )
}

export default EditorWrapper
