import { useEffect, useState } from 'react'
import { apiGetOne } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { CoreForm } from '../../components/CoreForm'
import { Descriptions, Flex, Skeleton, Tag } from 'antd'
import type { DescriptionsProps } from 'antd'
import { apiMe } from '../../api/user'
import { useAppSelector } from '../../hooks/useAppSelector'

const Profile = () => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<DescriptionsProps['items']>([])
  const [active, setAcive] = useState(false)
  const [role, setRole] = useState('user')
  const [avatar, setAvatar] = useState('')
  const user = useAppSelector(state => state.user.data)

  useEffect(() => {
    if (user) {
      setAcive(user?.is_active || false)
      setRole(user?.role || 'user')
      setAvatar(user?.avatar || '')
      const _items: DescriptionsProps['items'] = [
        {
          label: 'Фамилия',
          span: 3,
          children: <FormatField value={user?.lastname} type='text' />
        },
        {
          label: 'Имя',
          span: 3,
          children: <FormatField value={user?.firstname} type='text' />
        },
        {
          label: 'Баланс',
          span: 3,
          children: <FormatField value={user?.balance} type='money' />
        },
        {
          label: 'Электронная почта',
          span: 3,
          children: <FormatField value={user?.email} type='email' />
        },

        {
          label: 'Номер телефона',
          span: 3,
          children: <FormatField value={user?.phone} type='phone' />
        },
        {
          label: 'Телеграмм',
          span: 3,
          children: <FormatField value={user?.telegram} type='telegram' />
        }
      ]
      setItems(_items)
    }
  }, [])
  return (
    <>
      {items && (
        <CoreForm title=''>
          <Flex align='start' justify='start'>
            <Descriptions layout='horizontal' items={items} />
            <Flex vertical gap={8}>
              {avatar ? <img src={avatar} alt='' /> : <Skeleton.Image />}
              {active ? <Tag color='green'>Активен</Tag> : <Tag color='red'>Неактивен</Tag>}
              <FormatField value={role} type='role' />
            </Flex>
          </Flex>
        </CoreForm>
      )}
    </>
  )
}

export default Profile
