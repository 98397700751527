interface Voice {
  name: string
  sample: string
  value: string
  lang: string
}

export const voices: Voice[] = [
  { name: 'Эйша', value: 'f-us-1', lang: 'en', sample: '/assets/sample/EN_audio.wav' },
  { name: 'Эйша', value: 'f-de-1', lang: 'de', sample: '/assets/sample/DE_audio.wav' },
  { name: 'Эйша', value: 'f-fr-1', lang: 'fr', sample: '/assets/sample/FR_audio.wav' },
  { name: 'Эйша', value: 'f-it-1', lang: 'it', sample: '/assets/sample/IT_audio.wav' },
  { name: 'Эйша', value: 'f-pt-1', lang: 'pt', sample: '/assets/sample/PR_audio.wav' },
  { name: 'Эйша', value: 'f-es-1', lang: 'es', sample: '/assets/sample/SP_audio.wav' },
  { name: 'Эйша', value: 'f-tk-1', lang: 'tk', sample: '/assets/sample/TR_audio.wav' },

  { name: 'Роберт', value: 'm-us-1', lang: 'en', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-de-1', lang: 'de', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-fr-1', lang: 'fr', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-it-1', lang: 'it', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-pt-1', lang: 'pt', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-es-1', lang: 'es', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Роберт', value: 'm-tk-1', lang: 'tk', sample: '/assets/sample/Robert_sample.wav' },

  { name: 'Джон', value: 'm-us-2', lang: 'en', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-de-2', lang: 'de', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-fr-2', lang: 'fr', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-it-2', lang: 'it', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-pt-2', lang: 'pt', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-es-2', lang: 'es', sample: '/assets/sample/John_sample.wav' },
  { name: 'Джон', value: 'm-tk-2', lang: 'tk', sample: '/assets/sample/John_sample.wav' },

  { name: 'Том', value: 'm-us-3', lang: 'en', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-de-3', lang: 'de', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-fr-3', lang: 'fr', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-it-3', lang: 'it', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-pt-3', lang: 'pt', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-es-3', lang: 'es', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Том', value: 'm-tk-3', lang: 'tk', sample: '/assets/sample/Tom_sample.wav' },

  { name: 'Майкл', value: 'm-us-4', lang: 'en', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-de-4', lang: 'de', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-fr-4', lang: 'fr', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-it-4', lang: 'it', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-pt-4', lang: 'pt', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-es-4', lang: 'es', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Майкл', value: 'm-tk-4', lang: 'tk', sample: '/assets/sample/Michael_sample.wav' },

  { name: 'Рейчел', value: 'f-us-2', lang: 'en', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-de-2', lang: 'de', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-fr-2', lang: 'fr', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-it-2', lang: 'it', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-pt-2', lang: 'pt', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-es-2', lang: 'es', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Рейчел', value: 'f-tk-2', lang: 'tk', sample: '/assets/sample/Rachel_sample.wav' },

  { name: 'Эмми', value: 'f-us-3', lang: 'en', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-de-3', lang: 'de', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-fr-3', lang: 'fr', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-it-3', lang: 'it', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-pt-3', lang: 'pt', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-es-3', lang: 'es', sample: '/assets/sample/Ammy_sample.wav' },
  { name: 'Эмми', value: 'f-tk-3', lang: 'tk', sample: '/assets/sample/Ammy_sample.wav' },

  { name: 'Джейн', value: 'f-us-4', lang: 'en', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-de-4', lang: 'de', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-fr-4', lang: 'fr', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-it-4', lang: 'it', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-pt-4', lang: 'pt', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-es-4', lang: 'es', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Джейн', value: 'f-tk-4', lang: 'tk', sample: '/assets/sample/Jane_sample.wav' },

  { name: 'Лора', value: 'f-us-5', lang: 'en', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-de-5', lang: 'de', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-fr-5', lang: 'fr', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-it-5', lang: 'it', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-pt-5', lang: 'pt', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-es-5', lang: 'es', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Лора', value: 'f-tk-5', lang: 'tk', sample: '/assets/sample/Lora_sample.wav' }
]
