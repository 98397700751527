import React, { SyntheticEvent } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import hotkeys from 'hotkeys-js'

import { v4 as uuidv4 } from 'uuid'
// const uuidv4 = () => Math.random().toString();

import appStates from '../../../utils/editor/states'
import { simpleSort } from '../../../utils/editor/funcs'
import { Caption, export2srt, captionsCompare, captions2vtt } from '../../../utils/editor/caption'
import { SHOOT_TIME_MINOR, SHOOT_TIME_MAJOR, MAX_HISTORY } from '../../../utils/editor/consts'

import {
  Timeline,
  SubtitleTimeline,
  CaptionEditor,
  CaptionView,
  VideoPlayer2,
  VideoPlayer
} from '../../../components/Video'
import { CircleBtn } from '../../../components/CircleBtn'

import './Editor.scss'
import fileDownload from 'js-file-download'
// import { fetchGetTaskStatusContinue } from 'api/api'
import { getTaskIdFromUrl } from '../../../utils/editor/getTaskIdFromUrl'
import { apiCreate, apiGetBlob } from '../../../api/crud'

function copyReplace<T>(arr: T[], i: number, repl: T): T[] {
  return Object.assign([], arr, { [i]: repl })
}

type Props = { videoUrl: string; subtitles: Caption[]; id: string; setCurrent: any }

export default class Editor extends React.Component<
  Props,
  {
    videoUrl: string
    videoHeight: number
    acc: number

    subFileName: string

    currentTime: number
    totalTime: number

    captions: Caption[]
    selected_caption_i: number | null

    history: Caption[][]
    historyCursor: number

    setCurrent: any
  }
> {
  VideoPlayerRef: React.RefObject<VideoPlayer>

  constructor(props: Props) {
    super(props)
    this.state = {
      //   videoUrl: appStates.videoUrl.getData(),
      videoUrl: props.videoUrl,
      videoHeight: 400,
      acc: 0,
      currentTime: 0,
      totalTime: 0,

      subFileName: 'subtitle.srt',

      captions: [],
      selected_caption_i: null,

      historyCursor: -1,
      history: [],
      setCurrent: () => {}
    }

    this.VideoPlayerRef = React.createRef()

    // --- bind methods ---
    this.onTimeUpdate = this.onTimeUpdate.bind(this)
    this.onVideoError = this.onVideoError.bind(this)
    this.handleSeparatorDrag = this.handleSeparatorDrag.bind(this)
    this.handleSeparatorStop = this.handleSeparatorStop.bind(this)

    this.addCaptionUIHandler = this.addCaptionUIHandler.bind(this)
    this.changeCaptionUIHandler = this.changeCaptionUIHandler.bind(this)
    this.changeCaptionObject = this.changeCaptionObject.bind(this)
    this.deleteCaptionUIHandler = this.deleteCaptionUIHandler.bind(this)
    this.deleteCaptionObject = this.deleteCaptionObject.bind(this)
    this.captionSelectionToggle = this.captionSelectionToggle.bind(this)

    this.updatedHistory = this.updatedHistory.bind(this)
    this.undoRedo = this.undoRedo.bind(this)

    this.goToLastStart = this.goToLastStart.bind(this)
    this.goToNextEnd = this.goToNextEnd.bind(this)

    this.saveFile = this.saveFile.bind(this)
    this.onAccept = this.onAccept.bind(this)
  }

  // ------------- component API -----------------'

  componentDidMount() {
    // --- init states ---
    const initCaps = this.props.subtitles
    const id = this.props.id
    const initCaps2 = appStates.subtitles.getData()
    const setCurrent = this.props.setCurrent
    this.setState({
      captions: initCaps,
      ...this.updatedHistory(initCaps)
    })

    // --- bind shortcuts ---
    hotkeys.filter = () => true // to make it work also in input elements

    hotkeys('alt+*, tab, shift+tab', kv => kv.preventDefault())

    hotkeys('ctrl+shift+left', kv => {
      if (this.state.selected_caption_i === null) {
        kv.preventDefault()
        this.VideoPlayerRef.current?.shootTime(-SHOOT_TIME_MAJOR)
      }
    })
    hotkeys('ctrl+shift+right', kv => {
      if (this.state.selected_caption_i === null) {
        kv.preventDefault()
        this.VideoPlayerRef.current?.shootTime(+SHOOT_TIME_MAJOR)
      }
    })
    hotkeys('ctrl+6', kv => {
      kv.preventDefault()
      this.goToLastStart()
    })
    hotkeys('ctrl+7', kv => {
      kv.preventDefault()
      this.goToNextEnd()
    })
    hotkeys('ctrl+left', kv => {
      kv.preventDefault()
      this.VideoPlayerRef.current?.shootTime(-SHOOT_TIME_MINOR)
    })
    hotkeys('ctrl+right', kv => {
      kv.preventDefault()
      this.VideoPlayerRef.current?.shootTime(+SHOOT_TIME_MINOR)
    })

    hotkeys('ctrl+enter', () => this.addCaptionUIHandler())

    hotkeys('ctrl+down', kv => {
      kv.preventDefault()

      const t = this.state.currentTime,
        i = this.state.captions.findIndex(c => t >= c.start && t <= c.end)

      if (i !== -1) this.setState({ selected_caption_i: i })
    })
    hotkeys('escape', kv => {
      kv.preventDefault()
      this.setState({ selected_caption_i: null })
    })

    hotkeys('space', kv => {
      // @ts-ignore
      if (kv.target.tagName !== 'INPUT' && kv.target.tagName !== 'TEXTAREA') kv.preventDefault()
    })
    hotkeys('ctrl+space', kv => {
      this.VideoPlayerRef.current?.togglePlay()
    })

    hotkeys('ctrl+delete', () => this.deleteCaptionUIHandler())

    hotkeys('ctrl+z', () => this.undoRedo(true))
    hotkeys('ctrl+y', () => this.undoRedo(false))
    hotkeys('ctrl+s', kv => {
      kv.preventDefault()
      this.saveFile()
    })
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.subtitles !== this.props.subtitles) {
      this.setState({
        captions: this.props.subtitles
      })
    }
  }
  componentWillUnmount() {
    hotkeys.unbind()
  }

  // ----------- video player events -------------------

  onTimeUpdate(nt: number) {
    // nt: new time
    const sci = this.state.selected_caption_i
    if (sci !== null) {
      const cap = this.state.captions[sci],
        ve = this.VideoPlayerRef,
        isplying = ve.current?.isPlaying()

      if (nt >= cap.end) {
        if (isplying) {
          ve.current?.setPlay(false)
          ve.current?.setTime(cap.start)
        }
      } else if (nt < cap.start) {
        if (isplying) ve.current?.setTime(cap.start)
      }
    }

    this.setState({ currentTime: nt })
  }

  onVideoError(e: SyntheticEvent) {
    // pushToast({
    //   kind: 'danger',
    //   message: "error happend while loading video",
    //   duration: 5000
    // })
  }

  // ----------------- functionalities --------------------
  // -- captions changes

  changeCaptionObject(index: number, newcap: Caption): Caption[] {
    return copyReplace(this.state.captions, index, {
      ...newcap,
      hash: uuidv4()
    })
  }
  deleteCaptionObject(selected_i: number): Caption[] {
    let copy = [...this.state.captions],
      lastIndex = copy.length - 1

    if (selected_i !== lastIndex) copy[selected_i] = copy[lastIndex]

    copy.pop()

    return copy
  }

  addCaptionUIHandler() {
    let ct = this.state.currentTime,
      currentCap = this.state.captions.find(c => ct >= c.start && ct <= c.end),
      t = currentCap && currentCap.end - ct < 0.6 ? currentCap.end + 0.001 : ct,
      newCap = {
        start: t,
        end: t + 1,
        content: 'New Caption',
        hash: uuidv4()
      },
      caps = this.state.captions.concat(newCap)

    this.setState({
      captions: caps,
      selected_caption_i: this.state.captions.length,
      ...this.updatedHistory(caps)
    })
  }
  changeCaptionUIHandler(index: number, newCap: Caption) {
    if (index < 0 || index >= this.state.captions.length) return // it can happen due to fast repeative user actions

    let caps = this.changeCaptionObject(index, newCap)

    this.setState({
      captions: caps,
      ...this.updatedHistory(caps)
    })
  }
  deleteCaptionUIHandler() {
    if (this.state.selected_caption_i === null) return

    let caps = this.deleteCaptionObject(this.state.selected_caption_i)

    this.setState({
      selected_caption_i: null,
      captions: caps,
      ...this.updatedHistory(caps)
    })
  }

  updatedHistory(caps: Caption[]): object {
    let h = this.state.history,
      li = h.length - 1, // last index
      c = this.state.historyCursor

    if (c < li) h = [...h.slice(0, c + 1), caps]
    else h = [...h, caps]

    h = h.slice(-MAX_HISTORY)

    return {
      historyCursor: h.length - 1,
      history: h
    }
  }
  undoRedo(undo: boolean) {
    const hc = this.state.historyCursor,
      history = this.state.history,
      dir = undo ? -1 : +1

    // out of range check
    if (hc + dir < -1 || hc + dir >= history.length) return

    this.setState({
      selected_caption_i: null,
      captions: this.state.history[hc + (undo ? 0 : +1)],
      historyCursor: hc + dir
    })
  }

  // -- caption selection

  captionSelectionToggle(index: number | null) {
    this.setState({
      selected_caption_i: this.state.selected_caption_i === index ? null : index
    })
  }

  goToNextEnd() {
    // TODO optimize
    const ls = this.state,
      ends = ls.captions
        .filter(c => ls.currentTime < c.end)
        .map(c => c.end)
        .sort(simpleSort)

    if (ends.length) this.VideoPlayerRef.current?.setTime(ends[0])
  }

  goToLastStart() {
    const ls = this.state,
      starts = ls.captions
        .filter(c => ls.currentTime > c.start)
        .map(c => c.start)
        .sort(simpleSort)

    if (starts.length) this.VideoPlayerRef.current?.setTime(starts[starts.length - 1])
  }

  saveFile() {
    this.state.captions.sort(captionsCompare)
    fileDownload(export2srt(this.state.captions), this.state.subFileName)
  }

  onAccept() {
    const id = this.props.id
    const setCurrent = this.props.setCurrent
    if (!id) return

    const captions = [...this.state.captions].sort(captionsCompare)
    // fileDownload(export2srt(this.state.captions), this.state.subFileName);
    const srt = export2srt(captions)

    const srtFile = new File([srt], 'subtitle.srt', {
      type: 'text/plain'
    })

    const vtt = captions2vtt(captions)

    const saveSubtitle = async () => {
      const res = await apiCreate(`translate/${id}/rusub`, { id, vtt })
      if (res.isError) {
      } else {
        setCurrent(2)
      }
    }
    saveSubtitle()
  }

  handleSeparatorStop(e: DraggableEvent, dd: DraggableData) {
    this.setState({
      videoHeight: this.state.videoHeight + this.state.acc,
      acc: 0
    })
  }
  handleSeparatorDrag(e: DraggableEvent, dd: DraggableData) {
    this.setState({ acc: this.state.acc + dd.deltaY })
  }

  render() {
    const caps = this.state.captions,
      selected_ci = this.state.selected_caption_i

    return (
      <>
        {/* <h2 className="page-title">Studio</h2> */}
        <div className='wrapper'>
          <div className='video-wrapper'>
            <VideoPlayer2
              ref={this.VideoPlayerRef}
              videoUrl={this.props.videoUrl}
              onTimeUpdate={this.onTimeUpdate}
              onError={this.onVideoError}
              height={this.state.videoHeight}
              onDurationChanges={du => {
                this.setState({ totalTime: du })
              }}
              // onDurationChanges={(du) => this.setState({ totalTime: du })}
            />
          </div>

          <Draggable
            axis='y'
            position={{ x: 0, y: 0 }}
            onDrag={this.handleSeparatorDrag}
            onStop={this.handleSeparatorStop}
          >
            <div className='separator mt-3'></div>
          </Draggable>

          <CaptionView currentTime={this.state.currentTime} captions={caps} />
          <Timeline
            className='my-2'
            currentTime={this.state.currentTime}
            totalTime={this.state.totalTime}
            onSelectNewTime={nt => {
              this.VideoPlayerRef?.current?.setTime(nt)
            }}
          />

          <div className='d-flex justify-content-center action-button-group my-2 gap-3'>
            <CircleBtn iconClassName='fas fa-plus' text='Фраза' onClick={this.addCaptionUIHandler} />
            <CircleBtn
              iconClassName='fas fa-undo'
              disabled={this.state.historyCursor === -1}
              text={'Отменить ' + (this.state.historyCursor + 1)}
              onClick={() => this.undoRedo(true)}
              key={'back'}
            />

            <CircleBtn
              iconClassName='fas fa-redo'
              text={'Повторить ' + (this.state.history.length - 1 - this.state.historyCursor)}
              disabled={this.state.historyCursor >= this.state.history.length - 1}
              onClick={() => this.undoRedo(false)}
              key={'forward'}
            />

            <CircleBtn
              iconClassName='fas fa-times'
              disabled={this.state.selected_caption_i === null}
              text='Сбросить'
              onClick={() => this.setState({ selected_caption_i: null })}
              key={'reset'}
            />

            <CircleBtn
              iconClassName='fas fa-trash'
              disabled={this.state.selected_caption_i === null}
              text='Удалить'
              onClick={this.deleteCaptionUIHandler}
              key={'delete'}
            />

            <CircleBtn
              iconClassName='fas fa-chevron-left'
              disabled={caps.length === 0}
              text='Предыдущий'
              onClick={this.goToLastStart}
              key={'prev'}
            />
            <CircleBtn
              iconClassName='fas fa-chevron-right'
              disabled={caps.length === 0}
              text='Следующий '
              key={'next'}
              onClick={this.goToNextEnd}
            />
          </div>

          <SubtitleTimeline
            className='my-1'
            duration={this.state.totalTime}
            currentTime={this.state.currentTime}
            onSelectNewTime={nt => this.VideoPlayerRef.current?.setTime(nt)}
            captions={caps}
            onCaptionSelected={this.captionSelectionToggle}
            selectedCaption_i={selected_ci}
            onCaptionChanged={this.changeCaptionUIHandler}
          />

          <CaptionEditor
            currentTime={this.state.currentTime}
            totalTime={this.state.totalTime}
            caption={selected_ci === null ? null : caps[selected_ci]}
            captionIndex={selected_ci === null ? -1 : selected_ci}
            onCaptionChanged={this.changeCaptionUIHandler}
          />
        </div>

        {/* <div className='d-flex justify-content-center my-2 download-form'>
          <input
            className='form-control'
            placeholder='file-name.srt'
            value={this.state.subFileName}
            onFocus={() => this.setState({ selected_caption_i: null })}
            onChange={
              e => this.setState({ subFileName: e.target.value })
              //   this.setState({ subFileName: e.currentTarget.value })
            }
          />
          <button className='btn btn-danger' onClick={this.saveFile}>
            <strong>
              {' '}
              Сохранить файл <span className='fas fa-file'></span>{' '}
            </strong>
          </button>
        </div> */}
        <div className='d-flex mx-3 my-5 justify-content-end'>
          <button className='btn btn-success' onClick={this.onAccept}>
            Отправить на перевод и озвучку
          </button>
        </div>
      </>
    )
  }
}
