import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosProgressEvent } from 'axios';

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

const updateToken = async (url: string = '/auth/refresh_token') => {
    const token = localStorage.getItem('refreshToken');
    if (token) {
        try {
            const response = await axios.post(API_URL + url, {}, {
                headers: {
                    Authorization: token,
                }
            });
            const data = response.data;
            localStorage.setItem('accessToken', data?.access_token);
            localStorage.setItem('refreshToken', data?.refresh_token);
        } catch (error) {
            throw new Error('Forbidden');
        }
    }
}

const axiosUpload = async <T>(
    endpoint: string,
    config: AxiosRequestConfig = {},
    protectedMode: boolean = false,
    tryLogin: number = 2,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<Api.Response<T>> => {
    let countTryLogin = tryLogin;
    try {

        if (protectedMode) {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            } else {
                throw new Error('Need token');
            }
        }

        if (API_URL) {
            const response = await axios.request<T>({
                url: API_URL + endpoint,
                ...config,
                onUploadProgress
            });

            return {
                isError: false,
                data: response.data
            };
        } else {
            throw new Error('API URL not found');
        }
    } catch (error) {
        const err = error as AxiosError;
        if (err.response && err.response.status === 403 && countTryLogin >= 0) {
            await updateToken();
            countTryLogin -= 1;
            return axiosUpload(endpoint, config, protectedMode, countTryLogin);
        } else {
            return {
                isError: true,
                data: {
                    detail: [{
                        loc: ['unknown', 0],
                        msg: err.message,
                        type: 'unknown',
                    }],
                },
            };
        }
    }
}

export default axiosUpload;