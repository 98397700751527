import React from 'react'
import { Card, Col, Dropdown, Flex, Grid, Row, Spin, message } from 'antd'
import preview from '../../../assets/stab.png'
import { useNavigate } from 'react-router-dom'
import { apiGetBlob } from '../../../api/crud'
import fileDownload from 'js-file-download'
import type { MenuProps } from 'antd'
import { Rating } from '../../../components/Rating'
const SuccessVideo = ({ info }: { info: Api.Response.VideoTranslate }) => {
  const nav = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [loadingVideo, setLoadingVideo] = React.useState(false)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [img, setImg] = React.useState<string>(preview)
  const [video, setVideo] = React.useState()
  const [videoDownload, setVideoDownload] = React.useState()
  const items = [
    {
      key: '1',
      label: 'Скачать субтитры (перевод)'
    },
    {
      key: '2',
      label: 'Скачать субтитры (оригинал)'
    },
    {
      key: '3',
      label: 'Скачать видео (оригинал)'
    }
  ]
  const onMenuClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case '1':
        downloadResult(`sub_translate`, '.srt')
        break
      case '2':
        downloadResult(`sub_origin`, '.srt')
        break
      case '3':
        downloadResult(`video_origin`, '.mp4')
        break
    }
  }
  const downloadResult = async (url: string, format: '.mp4' | '.srt') => {
    setLoading(true)
    try {
      const req = await apiGetBlob(`translate/${info.id}/download/${url}/`)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      //@ts-ignore
      fileDownload(req.blob, `${info.video.name}${format}`)
    } catch (err) {
      console.error(err)
      messageApi.error('Произошла ошибка')
    }
    setLoading(false)
  }
  const getPreview = async () => {
    setLoadingVideo(true)
    try {
      const req = await apiGetBlob(`translate/${info.id}/download/preview/`)
      if (req.isError) {
        console.log(req.data);

        throw new Error(req.data.detail.toString())
      }
      //@ts-ignore
      setImg(URL.createObjectURL(req.blob))
    } catch (err) {
      console.error(err)
    }
  }
  const getVideo = async () => {
    try {
      setLoadingVideo(true)
      const req = await apiGetBlob(`translate/${info.id}/download/video_translate/`)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }

      //@ts-ignore
      setVideoDownload(req.blob)
      //@ts-ignore
      setVideo(URL.createObjectURL(req.blob))
      setLoadingVideo(false)
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    getPreview()
    getVideo()
  }, [])
  return (
    <>
      {contextHolder}
      <Row>
        <Col md={24}>
          <Flex align='center' justify='center' gap={20} style={{ paddingTop: '40px' }}>
            <Card title='Обработка видео завершилась' style={{ width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <Spin delay={500} spinning={loadingVideo}>
                  <video height={'300px'} poster={img} controls style={{ margin: '15px' }}>
                    {!loadingVideo && <source src={video} type='video/mp4' />}
                  </video>
                </Spin>

                <Flex align='center' justify='center' gap={10} vertical>
                  <Spin spinning={loading} delay={500}>
                    <Dropdown.Button
                      menu={{ items, onClick: onMenuClick }}
                      size='large'
                      type='primary'
                      onClick={() =>
                        videoDownload
                          ? fileDownload(videoDownload, `${info.video.name}.mp4`)
                          : downloadResult('video_translate', '.mp4')
                      }
                    >
                      Скачать видео
                    </Dropdown.Button>
                  </Spin>
                  {loading && <p>Файл подготавливается...</p>}
                </Flex>
              </div>
            </Card>
          </Flex>
        </Col>
        <Col md={24}>
          <Flex justify='center' style={{ marginTop: '25px', flexDirection: 'column' }}>
            <Rating id={info.id} />
          </Flex>
        </Col>
      </Row>
    </>
  )
}
export default SuccessVideo
