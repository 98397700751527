import React from 'react'
import { Form, Row, Col, Switch, Select, Typography, Space, Button, Tag, Divider, Flex, Tooltip, theme } from 'antd'
import { Link, useSearchParams } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Image } from 'antd/lib'
import VoiceList from './VoiceList'

const marginBottomStyle = {
  marginBottom: '0px'
}
const field = {
  0: 'Автоматически',
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10
}
const { useToken } = theme

interface VideoSettingsFormProps {
  form: FormInstance
  videoSettings: {
    language: string
    save_origin_voice: boolean
    has_logo: boolean
    notification: boolean
    voice_clone: boolean
    voice_gender: string
    lipsync: boolean
    subtitle_download: boolean
    subtitle_on_video: boolean
    subtitle_edit: boolean
    voice_count: number
  }
  handleSave: (values: any) => void
  price: Api.Response.VideoPrice | null
  user: Api.Response.User
  error: {
    notEnoughMoney: boolean
  }
  info: Api.Response.VideoTranslate | null
  startTranslate: () => void
  disabledLogo: boolean
}

const VideoSettingsForm: React.FC<VideoSettingsFormProps> = ({
  form,
  videoSettings,
  handleSave,
  startTranslate,
  price,
  user,
  error,
  info,
  disabledLogo
}) => {
  const { token } = useToken()
  const handleVoiceChange = (voice: string | null) => {
    form.setFieldsValue({ voice_gender: voice })
    handleSave({ voice_gender: voice })
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const isRestart = searchParams.get('restart')
  const deleteRestartParams = () => {
    if (isRestart === 'true') {
      searchParams.delete('restart')
      setSearchParams(searchParams)
    }
  }

  return (
    <Form form={form} onValuesChange={handleSave} onFinish={() => startTranslate()} style={{ padding: 18 }}>
      <Row
        align='stretch'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Видео</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='has_logo' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.has_logo} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Водяной знак на видео</Typography.Paragraph>
            </Space>

            <Space style={{ alignItems: 'center' }}>
              <Form.Item name='lipsync' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.lipsync} style={{ margin: 0 }} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Липсинк</Typography.Paragraph>
            </Space>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Субтитры</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_on_video' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_on_video} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Наложение субтитров</Typography.Paragraph>
            </Space>

            {/* <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_edit' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_edit} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Редактирование субтитров</Typography.Paragraph>
            </Space> */}
            {/* <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_download' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_download} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Скачивание субтитров</Typography.Paragraph>
            </Space> */}
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Аудио</Divider>

          <Flex vertical gap={20}>
            <Typography.Text style={{ marginBottom: '-20px' }}>Язык перевода</Typography.Text>
            <Form.Item name='language' style={{ marginBottom: '0px' }}>
              <Select
                defaultValue='en'
                style={{ maxWidth: '320px' }}
                options={[
                  {
                    value: 'en',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/GB.svg'} className='icons_flag' preview={false} /> Английский
                      </>
                    )
                  },
                  // {
                  //   value: 'ru',
                  //   label: (
                  //     <>
                  //       <Image src={'/assets/icons/flags/RU.svg'} className='icons_flag' preview={false} /> Русский
                  //     </>
                  //   )
                  // },
                  {
                    value: 'de',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/DE.svg'} className='icons_flag' preview={false} /> Немецкий
                      </>
                    )
                  },
                  {
                    value: 'fr',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/FR.svg'} className='icons_flag' preview={false} /> Французкий
                      </>
                    )
                  },
                  // {
                  //   value: 'ch',
                  //   label: (
                  //     <>
                  //       <Image src={'/assets/icons/flags/CN.svg'} className='icons_flag' preview={false} /> Китайский
                  //     </>
                  //   )
                  // },
                  {
                    value: 'it',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/IT.svg'} className='icons_flag' preview={false} /> Итальянский
                      </>
                    )
                  },
                  {
                    value: 'es',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/ES.svg'} className='icons_flag' preview={false} /> Испанский
                      </>
                    )
                  },
                  {
                    value: 'pt',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/PT.svg'} className='icons_flag' preview={false} />{' '}
                        Португальский
                      </>
                    )
                  },
                  {
                    value: 'tk',
                    label: (
                      <>
                        <Image src={'/assets/icons/flags/TR.svg'} className='icons_flag' preview={false} /> Турецкий
                      </>
                    )
                  }
                ]}
              />
            </Form.Item>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='save_origin_voice' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.save_origin_voice} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>
                Сохранить оригинальную приглушенную речь
              </Typography.Paragraph>
            </Space>

            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='voice_clone' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.voice_clone} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Клонировать голос</Typography.Paragraph>
            </Space>
            <div className={videoSettings.voice_clone ? 'hidden' : ''}>
              <div style={{ maxWidth: '320px' }}>
                <Divider plain style={{ marginTop: 0 }}>
                  или
                </Divider>
              </div>
              {/* Выбор спикера */}
              <Form.Item name='voice_gender' style={{ margin: 0 }}>
                <VoiceList onVoiceChange={handleVoiceChange} lang={videoSettings.language} />
              </Form.Item>
            </div>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Другое</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='notification' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.notification} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Получить уведомление</Typography.Paragraph>
            </Space>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='voice_count' style={{ margin: 0 }} label='Количество спикеров' labelAlign='left'>
                <Select
                  style={{ marginLeft: '5px', minWidth: '70px' }}
                  placeholder='Выберите'
                  size='small'
                  options={Object.entries(field).map(([key, value]) => ({
                    value: parseInt(key),
                    label: value
                  }))}
                />
              </Form.Item>
            </Space>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }}>
          <Form.Item name='userBalance' rules={[{ max: user?.balance }]}></Form.Item>
          <Flex align='flex-end' justify='flex-end' vertical>
            <Button
              type='primary'
              style={{ float: 'right' }}
              onClick={() => {
                deleteRestartParams()
                form.submit()
              }}
              disabled={error.notEnoughMoney}
              size='large'
            >
              Запустить перевод
            </Button>
            {error.notEnoughMoney && (
              <Link to='/app/balance' target='_blank' className='btn' style={{ float: 'right' }}>
                Пополнить баланс
              </Link>
            )}
          </Flex>
        </Col>
      </Row>
    </Form>
  )
}

export default VideoSettingsForm
